/** @format */

.button-check {
  min-width: 80px;
  outline: unset;
  border: 0 !important;
  height: 25px;
  color: white;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
