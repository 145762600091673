/** @format */

#meetingStartPages {
  position: relative;
  .meeting-container {
    position: relative;
    max-height: calc(var(--vh, 1vh) * 100);
    .control-container {
      width: 500px;
      height: 70px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 80px;
      background-color: #fff;
      flex-direction: row;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      left: 37%;
      .border-view {
        height: 40px;
        cursor: pointer;
        border-radius: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          font-size: 12px;
        }
      }
    }
    .video-title-grid-container {
      width: 100%;
      height: 100vh;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .video-container {
        // background-color: #333333;
        width: 70vw;
        height: 80vh;
        .video-container-left {
          width: 70%;
          height: 80vh;
          // background-color: #333333;
        }
        .video-container-right {
          width: 30%;
          padding-left: 30px;
          height: 80vh;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 10px;
            background-color: #333333;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #696969;
          }

          .video-remote-container {
            .video-remote {
              width: 100%;
              height: 210px;
              background-color: #333333;
            }
          }
        }
      }
    }
  }
  .loading-video-call {
    position: absolute;
    top: 47%;
    left: 48.5%;
  }
  @media only screen and (max-width: 1400px) {
    .video-container {
      height: 65vh !important;
      .video-container-left {
        height: 65vh !important;
      }
      .video-container-right {
        height: 100% !important ;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .video-container {
      width: 85vw !important;
      height: 65vh !important;
      .video-container-left {
        height: 65vh !important;
      }
    }
    .control-container {
      left: 30% !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .control-container {
      left: 20% !important;
    }
  }
  @media only screen and (max-width: 800px) {
    .video-container {
      width: 100% !important;
      height: 100% !important;
      .video-container-left {
        width: 100% !important;
        height: calc(var(--vh, 1vh) * 100) !important;
        .remote-video {
          video {
            -o-object-fit: contain !important;
            object-fit: contain !important;
          }
        }
      }
      .video-container-right {
        width: 40% !important;
        overflow-y: unset !important;
        position: absolute;
        z-index: 1000;
        height: unset !important;
        padding-left: 0 !important;
        right: 0;
        bottom: 70px;
        max-height: 450px !important;
      }
    }
    .control-container {
      width: 100% !important;
      padding: 0px 30px !important;
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
      left: 0 !important;
    }
  }
}

.modal--leave-meeting,
.modal--end-meeting {
  z-index: 11000;
}

.container-modal-bottom {
  display: flex;
  justify-content: end;
  padding: 10px;
  .btn-cancel {
    padding: 5px;
    border: 0px;
    background-color: #fff;
    font-size: 12px;
    margin-right: 20px;
  }
  .btn-leave {
    padding: 5px;
    border: 0px;
    font-size: 12px;
    background-color: red;
    color: #fff;
  }
}
