#slideBurgerMenu {
  .bm-menu-wrap {
    padding-top: 20px;
    top: 100px;
    background-color: #fff;
    height: 80vh !important;
    .bm-menu {
      border-top: 1px solid #c0c0c0 !important;
      margin-left: 20px;
      &::-webkit-scrollbar-track {
        background: #000000 !important;
      }
    }
  }
  .bm-burger-button {
    display: none;
  }
  .container-theme {
    width: 40%;
  }
  .bm-overlay {
    display: none;
  }
  .bm-item{height: 100%}
  @media only screen and (max-width: 800px) {
    .bm-menu-wrap {
      width: 100vw !important;
      top: unset !important;
      height: 30vh !important;
      bottom: 70px;
    }
  }
}
